.timerSelect {
    position: fixed;
    top: calc(50vh - 150px);
    left: calc(50vw - 150px);
    right: calc(50vw - 150px);
    bottom: calc(50vh - 50px);

    background-color: var(--bg);
    z-index: 12;
}

.timerSelect,
.timerSelect__setter,
.timerSelect__setters {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.timerSelect {
    flex-direction: column;
}

.timerSelect h1 {
    margin-top: 0;
}

.timerSelect span {
    margin: 0 4px;
}

.timerSelect__setter {
    height: 50px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}