
.editableIngredient {
    position: relative;
    display: flex;
    align-items: center;

    margin-bottom: 15px;
    border-radius: 5px;
    z-index: 2;
}

.sortList .editableIngredient {
    margin-bottom: 0;
}

.editableIngredient:not(.editable) {
    padding: 10px;
}

.editableIngredient.editable {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    border-radius: 10px;
}

.editableIngredient.editable > div:not(.editableIngredient__value):not(.removeButtonContainer) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.editableIngredient__value {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.editableIngredient__inputWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.editableIngredient__value > span {
    display: flex;
    align-items: center;
}

.editableIngredient__value > span:last-child {
    justify-content: flex-start;
    padding-right: 10px;
}
.editableIngredient__value > span:first-child {
    justify-content: flex-end;
    padding-left: 10px;
}

.editableIngredient input {
    width: 40px;
    text-align: center;
    border: none;
    border-radius: 4px;
    padding: 5px;
    margin: 5px;
}

.editableIngredient__quantityButton {
    padding: 0;

    width: 64px;
    height: 30px;

    border-radius: 10px;

    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}


.editableIngredient .removeButtonContainer {
    position: absolute;
    top: 0;
    right: -50px;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;
}

.editableIngredient .removeButtonContainer button{
    cursor: pointer;
}

.editableIngredient .removeButton {

}