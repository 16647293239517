
.ingredients__ingredient {
    border-radius: 5px;
    margin-bottom: 15px;
}


.editIngredient {
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 15px;
}

.editIngredient.fullScreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: var(--bg);
    z-index: 13;

    justify-content: flex-start;
}

.editIngredient .header {
    margin-top: 0;
}

.editIngredient__fields {
    display: grid;
    grid-template-columns: auto 1fr;
    row-gap: 8px;
    column-gap: 8px;
}

.mainHeader {
    position: sticky;
    top: 0;
    height: 70px;
    margin: 0;
    background-color: var(--bg);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3em;
}
