.backButton,
.menuButton {
    position: fixed;
    top: 10px;
    z-index: 13;
    font-weight: bold;
    font-size: 1.3em;
    background-color: var(--bg);
    padding: 0;
}

.menuButton,
.menuButton svg {
    cursor: pointer;
    height: 50px;
    width: 50px;
}

.backButton {
    font-size: 1.5em;
    padding: 6px 13px;
    left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menuButton {
    right: 10px;
}

.menu {
    padding-top: 80px;

    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: var(--bg);
    z-index: 12;

    position: fixed;
    top: 0;
    left: calc(100vw + 20px);
    right: calc(-80vw - 20px);
    bottom: 0;
    transition: left 400ms, right 400ms;
}

.menu.show {
    left: 20vw;
    right: 0;
}


.menuButton line {
    stroke: var(--contrast);
}

.menuButton .line1,
.menuButton .line3 {
    transition: rotate, translate, 500ms;
}

.menuButton .line2 {
    transition: scaleX, 500ms;
}

.menuButton .line1 {
    transform-origin: 18px 24px;
}

.menuButton .line3 {
    transform-origin: 18px 40px;
}

.menuButton .line2 {
    transform-origin: 34px 33px;
}

.menuButton.open .line1 {
    transform: rotate(45deg) translate(1px, -5px);
}

.menuButton.open .line3 {
    transform: rotate(-45deg) translate(1px, 5px);
}

.menuButton.open .line2 {
    transform: scaleX(0);
}

.menu__colorButtons {
    margin-top: 40px;
}

.menu__colorButtons button {
    margin-bottom: 10px;
}

.menu__colorButtons button.blue {
    background-color: var(--blue);
}

.menu__colorButtons button.grey {
    background-color: var(--grey);
}
