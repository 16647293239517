.searchSelector {
    padding: 20px 15px;
    border-radius: 8px;
    margin: 5px auto 0 auto;
    width: 202px;
    max-width: min(202px, (100vw - 40px));
}

.searchSelector input {
    max-width: min(150px, (100vw - 60px));
    margin-bottom: 15px;
}

.sortList {
    padding: 0 50px;
}

.draggable {
    position: relative;
}

.sortList__handle__wrapper {
    position: absolute;
    top: 0;
    left: -50px;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;
}

.sortList__handle {
    background-color: var(--bg);
}

.sortList .listable {
    margin-bottom: 0;
    background-color: var(--bg);
}

.sortList .draggable {
    margin-bottom: 20px;
}