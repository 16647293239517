.recipe p {
    margin-top: 0;
}

.recipe .reservation__ingredient {
    padding: 10px 20px;
}

.recipe .popOut {
    border-radius: 5px;
}

.reservation__ingredient {
    text-align: center;
}

.reservation__step {
    padding: 10px 40px;
    margin-bottom: 20px;
}

.reservation__step .header {
    margin-top: 0;
}