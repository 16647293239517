.editStep {
    display: flex;
    flex-direction: column;
    align-items: center;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--bg);
    z-index: 20;
}

.editStep .textarea {
    width: 340px;
    max-width: calc(100vw - 160px);
    display: block;
    overflow: hidden;
    min-height: 100px;
    line-height: 20px;
    padding: 10px 15px;
}

/*.textarea[contenteditable]:empty::before {*/
/*    content: "Placeholder still possible";*/
/*    color: gray;*/
/*}*/

.recipeStep {
    /*padding: 10px;*/
    /*background-color: red;*/
}

.editStep .cancelConfirm {
    padding-top: 20px;
}

.editTimerButton {
    margin-top: 20px;
}