.recipes__recipeWrapper {
    position: relative;
}

.recipes__editRecipeButton {
    height: 30px;
    width: 30px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.editRecipe {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
