body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /*--bg: #e3e6e8;*/
  /*!*--bg: #263d4c;*!*/

  /*--confirm: #5eff5e;*/
  /*--confirm-semi-transparent: #5eff5e50;*/
  /*--confirm-transparent: #5eff5e20;*/

  /*--cancel: #ff573d;*/
  /*--cancel-semi-transparent: #ff573d50;*/
  /*--cancel-transparent: #ff573d20;*/

  /*--disabled: #868686;*/
  /*--disabled-semi-transparent: #86868670;*/
  /*--disabled-transparent: #86868650;*/

}

input,
.textarea,
textarea {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  outline: none;
  max-width: calc(100vw - 4px);
  background-color: var(--bg);

  box-shadow: inset 2px 2px 5px var(--shadow),
  inset -2px -2px 5px var(--highlight);
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 1.2em;
}

.textCenter {
  text-align: center;
}

.flexcenter {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.flexRowWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.flexRowWrap > *{
  margin-right: 10px;
  margin-left: 10px;
}

.justifyStart {
  justify-content: flex-start;
}

button {
  background-color: rgba(0,0,0,0);
  border: none;
  outline: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

input,
button {
  color: var(--contrast);
}

svg {
  fill: var(--contrast);
  stroke: var(--contrast)
}

.button {
  box-shadow:  3px 3px 7px var(--shadow),
  -3px -3px 7px var(--highlight);
  padding: 10px 20px;
  border-radius: 4px;
}

a.button {
  display: block;
  text-decoration: none;
  color: var(--contrast);
}

.roundButton,
a.roundButton {
  padding: 0;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.roundButton.small {
  height: 40px;
  width: 40px;
}

a.backButton {
  padding: 5px 13px 7px 13px;
}

button.disabled,
.button:not(.disabled):active {
  box-shadow:  1px 1px 3px var(--shadow),
  -1px -1px 5px var(--highlight);
}

button.disabled {
  opacity: .7;
  color: #444;
}

.cancelConfirm {
  padding-top: 10px;

}

.cancelConfirm button {
  width: 100px;
  margin: 0 5px;
}

.cancelButton {
  background-color: var(--cancel-transparent);
}

.confirmButton {
  background-color: var(--confirm-transparent);
}

.scrollSafe {
  padding: 10px 10px 50px  10px;
  overflow: auto;
}