.App {
  --blue: #263d4c;
  --grey: #e3e6e8;

  --bg: var(--blue);
  --shadow: #1c2f3b;
  --highlight: #38566a;
  --contrast: white;

  --confirm: #5eff5e;
  --confirm-semi-transparent: #5eff5e50;
  --confirm-transparent: #5eff5e20;

  --cancel: #ff573d;
  --cancel-semi-transparent: #ff573d50;
  --cancel-transparent: #ff573d20;

  --disabled: #868686;
  --disabled-semi-transparent: #86868670;
  --disabled-transparent: #86868650;
}

.App.grey {
  --bg: var(--grey);
  --shadow: #b3b6b7;
  --highlight: #ffffff;

  --contrast: black;
}

.App {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--bg);
  overflow: auto;
  color: var(--contrast);

  display: flex;
  justify-content: center;
}

.app__content {
  max-width: 800px;
}

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
}

.navButton {
  margin-bottom: 10px;
  font-size: 1.2em;
}

.listable {
  margin-bottom: 15px;
  font-size: 1.2em;
}

.editableHeader {
  border: none;
  text-align: center;
  font-weight: bold;
  font-size: 1.5em;
  margin: 20px 0;
  padding: 10px;
  outline: none;
  width: 350px;
  max-width: calc(100vw - 150px);
  border-radius: 5px;
}

.popOut {
  box-shadow:  3px 3px 7px var(--shadow),
  -3px -3px 7px var(--highlight);
  /*box-shadow:  3px 3px 7px #b3b6b7,*/
  /*-3px -3px 7px #ffffff;*/
}

.popIn {
  box-shadow: inset 3px 3px 7px var(--shadow),
  inset -3px -3px 7px var(--highlight);
  /*box-shadow: inset 3px 3px 7px #b3b6b7,*/
  /*inset -3px -3px 7px #ffffff;*/
}

.input__label {
  margin-right: 5px;
}

.noMarginTop {
  margin-top: 0;
}

.touchOutsideSensor {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
}

@media (min-width: 400px) {
  .page {
    padding: 0 40px;
  }
}

@media (min-width: 450px) {
  .page {
    padding: 0 60px;
  }
}